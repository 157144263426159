<template>
  <div>
    <old-calendar v-if="user"></old-calendar>
    <div class="calendar-loader-container" v-if="!user">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import OldCalendar from "./oldCalendar";
import { mapState } from "vuex";

export default {
  name: "oldCalendarMiddleware",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  components: {
    OldCalendar,
  },
};
</script>
<style scoped lang="scss">
.calendar-loader-container {
  width: 100%;
  height: 65vh;
  position: relative;

  .loader {
    position: absolute;
    background-color: transparent;
  }
}
</style>
